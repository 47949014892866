import React from 'react';

const Testimonials = ({ data }) => {
  const { from, contact, text } = data;

  return (
    <div className="testimonialContainer">
      <h4>{`De: ${from}`}</h4>
      <h4>{`Contacto: ${contact}`}</h4>
      <p>{text}</p>
    </div>
  );
};

export default Testimonials;
