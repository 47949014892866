import React, { useState, useCallback, Fragment } from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import viajesBackground from '../assets/images/Viajes/viajesBackground.jpg';
import viajes1 from '../assets/images/Viajes/big-ben.jpg';
import Banner from '../components/Banner';
import SelectorButton from '../components/SelectorButton';
import Londres from '../components/Viajes/Londres';
import Disney from '../components/Viajes/Disney';
import Testimonials from '../components/Viajes/Testimonials';
import { testimonials } from '../assets/texts/viajes/testimonials';
import DrawerButton from '../components/DrawerButton';
import Futbol from '../components/Viajes/Futbol';

const Viajes = () => {
  const [isFirstSelected, setIsFirstSelected] = useState(true);
  const [isSecondSelected, setIsSecondSelected] = useState(false);
  const [isThirdSelected, setIsThirdSelected] = useState(false);

  const handleSelectionFirstPress = useCallback(() => {
    setIsFirstSelected(true);
    setIsSecondSelected(false);
    setIsThirdSelected(false);
  }, []);

  const handleSelectionSecondPress = useCallback(() => {
    setIsSecondSelected(true);
    setIsFirstSelected(false);
    setIsThirdSelected(false);
  }, []);

  const handleSelectionThirdPress = useCallback(() => {
    setIsThirdSelected(true);
    setIsFirstSelected(false);
    setIsSecondSelected(false);
  }, []);

  return (
    <Fragment>
      <DrawerButton />
      <Layout>
        <section id="wrapper">
          <Banner
            title="Viajes"
            subtitle="Este programa permite a nuestros alumnos y público externo vivir una
gran experiencia en una de las mejores capitales del mundo"
            backgroundImage={`url("${viajesBackground}")`}
          />
          <div className="viajesContentContainer">
            <div className="introductionContainer">
              <img className="londonEye" src={viajes1} alt="" />
              <p className="introductionText">
                Desde el año 2009 nuestra institución trabaja en conjunto con
                Bloomsbury International para ofrecer a los alumnos la
                posibilidad de complementar su aprendizaje con un viaje de
                estudios al Reino Unido. <br></br>
                <br></br>Esta experiencia permite a los alumnos comunicarse en
                inglés en situaciones cotidianas, ampliar el vocabulario y el
                uso de expresiones idiomáticas, mejorar su comprensión auditiva
                y adquirir confianza en su capacidad para expresarse eficaz y
                apropiadamente en el idioma.
                <br></br>
                <br></br>Durante este viaje los chicos participan de visitas a
                los lugares emblemáticos de Inglaterra y disfrutan de una
                experiencia inolvidable de crecimiento personal.
              </p>
            </div>
            <div className="viajesContainer">
              <h2>Viajes disponibles</h2>
              <div className="buttonContainer">
                <SelectorButton
                  label="¡Viajes a Londres!"
                  isSelected={isFirstSelected}
                  onSelectionPress={handleSelectionFirstPress}
                />
                <SelectorButton
                  label="¡Londres - Paris!"
                  isSelected={isSecondSelected}
                  onSelectionPress={handleSelectionSecondPress}
                />
                <SelectorButton
                  label="¡English + Football!"
                  isSelected={isThirdSelected}
                  onSelectionPress={handleSelectionThirdPress}
                />
              </div>
              {isFirstSelected && <Londres />}
              {isSecondSelected && <Disney />}
              {isThirdSelected && <Futbol />}
              <div className="testimonialsContainer">
                <h1>Testimonials</h1>
                <Testimonials data={testimonials.first} />
                <Testimonials data={testimonials.second} />
                <Testimonials data={testimonials.third} />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    </Fragment>
  );
};

export default Viajes;
