export const bulletsFutbol = [
  'Matrícula de Inscripción.',
  'Curso de Inglés de TRES SEMANAS de duración, de lunes a viernes, por la mañana, de 9 a 12hs, en el Instituto BLOOMSBURY INTERNATIONAL, centro Londres.',
  '3 sesiones de práctica de football por semana en Eton College',
  'Todo el kit de football – *los estudiantes deberán llevar sus propios botines y canilleras',
  'Oportunidad de entrenar con jugadores locales',
  'Opciones de ver un partido profesional, tour a un estadio de la premiere league',
  'Niveles desde Principiantes a Avanzado. Grupos reducidos integrados por alumnos de todo el mundo.',
  'Evaluación de nivel y progreso en Argentina e Inglaterra.',
  'Pack de bienvenida. Wi-Fi gratis en el Instituto.',
  'Certificado de Estudios.',
  'Traslado Aeropuerto-Casa de Familia- Aeropuerto (en Londres)',
  'Alojamiento en Casa de Familia. Habitación doble. Régimen de Pensión completa (Desayuno, almuerzo y Cena).',
  'Coordinación y reuniones de preparación antes del viaje de estudio.',
];
