import React from 'react';

const ArticleHeader = ({ title, subtitle }) => {
  return (
    <section id="articleHeader">
      <div className="articleHeaderContainer">
        <div className="titleContainer">
          <h1>{title}</h1>
          <div className="separator" />
          <h3>{subtitle}</h3>
        </div>
      </div>
    </section>
  );
};

export default ArticleHeader;
