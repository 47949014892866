import React from 'react';

const BulletText = ({ data }) => {
  return (
    <ul>
      {data.map(value => {
        return <li>{value}</li>;
      })}
    </ul>
  );
};

export default BulletText;
