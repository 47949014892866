export const testimonials = {
  first: {
    from: 'Tomás Villanueva',
    contact: 'tomasino-@hotmail.com',
    text:
      'Mi nombre es Tomás, viajé a Londres con Oxford House en enero de 2017, con el coordinador Victor. La experiencia fue realmente maravillosa y enriquecedora. Comenzamos el viaje saliendo del aeropuerto de Ezeiza en Buenos Aires, en todo momento estuvimos acompañados por el coordinador, el vuelo salió en horario y llegamos sin ningún inconveniente al aeropuerto internacional Heathrow en Londres. Nos recibió el coordinador que nos iba a acompañar en Londres por dos semanas, y nos fuimos entonces en combi ya al centro de la ciudad. Una vez allí, nos encontramos con la persona que nos alojó en toda la estadía en Londres. Nos llevó hasta la casa, muy acogedora por suerte, para contrastar con el clima frío de Londres en invierno. Luego, ya comenzando la semana, viajamos al instituto Bloomsbury mediante el excelente transporte público, al que iríamos a partir de entonces de Lunes a Viernes todas las mañanas. Las clases fueron siempre muy divertidas y dinámicas sin dejar de aprender, con compañeros de todas partes del mundo (Corea del Sur, Rusia, Italia, Turquía, etc) con los que pudimos intercambiar culturalmente y hacernos amigos. Al finalizar las clases, pasamos todas las tardes recorriendo los diferentes "landmarks" de Londres, como el BigBen, el London Eye, el British Museum, el Museo de Historia Natural de Londres, el museo de Guerra, y muchos otros más, lugares fascinantes e igual de interesantes; incontable cantidad de lugares que explorar y disfrutar en esta hermosa ciudad. Aun así tuvimos tiempo de viajar y visitar otros lugares o pintorescas ciudades, como fueron Oxford, Bath o el StoneHenge.',
  },

  second: {
    from: 'Lucas Bentancor',
    contact: 'lucas.bentancor@outlook.es',
    text:
      'Buenas, soy Lucas Bentancor estudiante de Oxford en Tierra del fuego, Ushuaia. Hace mas de un año que viaje con el instituto a Londres para generar una mejoria en el habla y escucha del ingles pero me sorprendí al ir pasando los días y notar que ademas de las mejoras de ingles que se obtienen de una forma fluida y constante por el cambio rotundo del espacio geografico, en donde solamente esta el ingles, hubo tambien experiencias enriquecedoras en mi vida que hoy en día estoy aplicando en lo cotidiano, generar relaciones con distintas culturas, genererar ambiente de equipo con el grupo, ponerse uno mismo en situaciones para crecer y demas experiencias que los invito a que vivan en carne propía no simplemente para elevar el nivel de ingles, sino para crecer en lo personal y en paralelo perfeccionar escritura, escucha y habla fluida del ingles.',
  },

  third: {
    from: 'Jazmin Parafioriti',
    contact: 'jazzparafioriti@gmail.com',
    text:
      'El viaje de estudio a Londres fue una hermosa experiencias. Lo disfruté desde el primer momento, porque a pesar de ser un grupo chico nos llevamos muy bien y Victor (nuestro profesor) nos hizo sentir cómodos en todo momento. El día que llegamos a Londres la familia que nos hospedó nos dio una muy linda bienvenida, todos en la casa eran muy amables y atentos. Como ellos cenen a las 7/8 de la noche siempre nos dejaban la comida preparada para nosotras y otras dos chicas brasileñas que se hospedaron en la casa. Visitamos muchísimos lugares como parques ,museos y barrios muy característicos, cada uno más lindo que otro. La verdad que de todas las fotos que saque me quede sin memoria jajaja. Fue un viaje inolvidable, y estoy segura que cualquiera que tenga la oportunidad de hacerlo lo va a disfrutar tanto como yo',
  },
};
