import React from 'react';

const RedBox = () => {
  return (
    <div id="redBox">
      <div className="redBoxContainer">
        <p>
          En caso de estar interesado en nuestro programa, puede solicitar una
          reunión informativa donde daremos más detalles del viaje a través de
          una presentación con fotos y videos.
        </p>
      </div>
    </div>
  );
};

export default RedBox;
