import React from 'react';

const ArticleQuestions = ({
  question,
  subtitle,
  answer,
  children,
  className,
}) => {
  return (
    <div id="articleQuestion">
      <div className="questionContainer">
        <h3>{question}</h3>
        {subtitle && <h4>{subtitle}</h4>}
        {answer && <p className="questionText">{answer}</p>}
        {children}
      </div>
    </div>
  );
};

export default ArticleQuestions;
