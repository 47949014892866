export const bulletsDisney = [
  'Curso de inglés general de tres semanas de duración, de 20 lecciones semanales.',
  'Máximo 12/14 alumnos por aula. Niveles desde principiante a avanzado, debiendo recurrir al uso del inglés como herramienta de comunicación primordial',
  'Prueba de nivelación',
  'Biblioteca, laboratorio de idiomas, centro multimedia, pizarras interactivas y computadoras con libre acceso a servicio de correo e internet',
  'Certificado de estudio de Bloomsbury International',
  'Actividades sociales y recreativas por el colegio.',
  'Trabajo de campo en Londres',
  'Alojamiento en casa de familia base habitación doble con régimen de pensión completa (desayuno, almuerzo y cena) o residencia estudiantil.',
  'Traslado del aeropuerto de llegada al punto de encuentro con las familias y de retorno al aeropuerto.',
  'Excursiones',
  'Paseo dia completo en Oxford, Windsor & Eton',
  'Paseo fin de semana Sábado y domingo en Paris Eurodisney',
];
