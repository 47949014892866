import React from 'react';

import bridge from '../../assets/images/Viajes/bridge.jpg';
import gardens from '../../assets/images/Viajes/gardens.jpg';
import londonEye from '../../assets/images/Viajes/london-eye.jpg';
import ArticleHeader from '../../components/Article/ArticleHeader';
import ArticleQuestions from '../../components/Article/ArticleQuestions';
import BulletText from '../../components/BulletText';
import { bulletsLondon } from '../../assets/texts/bullets/london';
import RedBox from '../../components/Article/RedBox';
import ArticleFooter from '../../components/Article/ArticleFooter';

const Londres = () => {
  return (
    <div className="articleContainer">
      <img src={londonEye} className="londonEye" alt="London Eye" />

      <ArticleHeader
        title="Viaje a Londres"
        subtitle="Propuesta académica, social y recreativa"
      />
      <ArticleQuestions
        question="¿Qué te proponemos?"
        answer="Estudiar inglés en Londres - Inglaterra, divertirte con amigos, conocer y disfrutar una de las capitales más modernas del mundo."
      />
      <ArticleQuestions
        question="¿Cuándo?"
        answer="En Enero / Julio 2020 - 2021"
      />
      <img src={bridge} className="bridge" alt="Bridge of London" />
      <ArticleQuestions
        question="¿Dónde?"
        answer={
          'En BLOOMSBURY COLLEGE CENTRAL LONDON, que es un colegio de enseñanza del idioma inglés para estudiantes no-nativos reconocido por el consejo británico y miembro de English UK.\nBloomsbury, que además es el nombre de la zona donde se encuentra el colegio, es un famoso sitio literario y académico en pleno corazón de Londres, muy cercano al Museo Británico con toda la vida y movimiento de esta gran ciudad.\nEl colegio se encuentra emplazado en un edificio clásico totalmente modernizado, ofreciendo a los estudiantes una gran variedad de instalaciones que incluyen biblioteca con centro de auto estudio, laboratorio de idiomas, computadoras además de un pequeño espacio de esparcimiento y cafetería.'
        }
      />
      <div className="articleQuestionIncluye">
        <ArticleQuestions question="¿Qué incluye?">
          <BulletText data={bulletsLondon} />
        </ArticleQuestions>
      </div>
      <img className="gardens" src={gardens} alt="" />
      <RedBox />
      <ArticleQuestions
        question="Actividades que hacer en Londres"
        subtitle="(Gratuitas y pagas por los interesados)"
        answer={
          'Museos.- British museum, national gallery, el museo de arte moderno, museo de guerra, museo de ciencias naturales (gratuito) \n\nAtracciones.- London eye, London dungeon, madame taussauds, sea life,Shreck, paseo en el rio tamesis, proyección 4D antes de ir al London eye en días diferentes.\n\nPaseos por la ciudad.- Compras en Oxford Street, camdem town (punk – rock), Big ben, china town, bahía de westminster, Buckingham Palace, la city (corazón financiero de Londres, paseo en la estación King’s Cross (Harry potter plataforma 9 3⁄4) Barrio Soho, Abbey road, Hyde Park, Obras de teatro como el rey león o Harry potter , partidos de football o rugby, Tower Bridge.\n\nFines de semana.- Cambridge, Bath y stonehenge, Thorpe Park, Harry Potter studios entre otros.'
        }
      />
      <ArticleFooter />
    </div>
  );
};

export default Londres;
