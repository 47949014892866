import React from 'react';

import telephoneBooth from '../../assets/images/Viajes/telephone-booth.jpg';
import gardens from '../../assets/images/Viajes/gardens.jpg';
import ball from '../../assets/images/Viajes/ball.jpg';
import ArticleHeader from '../../components/Article/ArticleHeader';
import ArticleQuestions from '../../components/Article/ArticleQuestions';
import BulletText from '../../components/BulletText';
import { bulletsFutbol } from '../../assets/texts/bullets/futbol';
import RedBox from '../../components/Article/RedBox';
import ArticleFooter from '../../components/Article/ArticleFooter';

const Futbol = () => {
  return (
    <div className="articleContainer">
      <img
        src={telephoneBooth}
        className="telephoneBooth"
        alt="Telephone Booth"
      />

      <ArticleHeader
        title="English + Football"
        subtitle="Propuesta académica, social y recreativa"
      />
      <ArticleQuestions
        question="¿Qué te proponemos?"
        answer={
          'Estudiar en inglés en Londres - Inglaterra, divertirte con amigos, conocer y disfrutar una de las capitales más modernas del mundo. Nuestro objetivo es ofrecer a los estudiantes de inglés de todos los niveles y edades, la posibilidad de “vivir el idioma” a través de los “programas de inmersión total”, combina un curso intensivo de 3 semanas, en uno de los institutos de inglés más prestigiosos del centro de Londres, con prácticas de football y vivir una experiencia inolvidable jugando con las mejores academias de footbal.\nTambién una serie de actividades sociales y culturales programadas por Londres durante los días de semana (como paseos por la ciudad, visitas a palacios, castillos, museos, paseos de compras, salidas al teatro, paseos en barcos, visitas a catedrales, y muchísimos otros lugares de interés turístico) y salidas opcionales, los fines de semana, a otras ciudades de Reino Unidos como Oxford, Cambridge, Windsor etc. Vivir durante una temporada en el extranjero, ayuda a practicar el idioma en situaciones reales de la vida cotidiana.'
        }
      />
      <ArticleQuestions
        question="¿Cuándo?"
        answer="En Enero / Julio 2020 - 2021"
      />
      <img src={ball} className="bridge" alt="Europe night life" />
      <ArticleQuestions
        question="¿Dónde?"
        answer={
          'En BLOOMSBURY COLLEGE CENTRAL LONDON, que es un colegio de enseñanza del idioma inglés para estudiantes no-nativos reconocido por el consejo británico y miembro de English UK.\nBloomsbury, que además es el nombre de la zona donde se encuentra el colegio, es un famoso sitio literario y académico en pleno corazón de Londres, muy cercano al Museo Británico con toda la vida y movimiento de esta gran ciudad.\nEl colegio se encuentra emplazado en un edificio clásico totalmente modernizado, ofreciendo a los estudiantes una gran variedad de instalaciones que incluyen biblioteca con centro de auto estudio, laboratorio de idiomas, computadoras además de un pequeño espacio de esparcimiento y cafetería.'
        }
      />
      <div className="articleQuestionIncluye">
        <ArticleQuestions question="¿Qué incluye?">
          <BulletText data={bulletsFutbol} />
        </ArticleQuestions>
      </div>
      <img className="gardens" src={gardens} alt="" />
      <RedBox />
      <ArticleQuestions
        question="Actividades que hacer en Londres"
        subtitle="(Gratuitas y pagas por los interesados)"
        answer={
          'Museos.- British museum, national gallery, el museo de arte moderno, museo de guerra, museo de ciencias naturales (gratuito) \n\nAtracciones.- London eye, London dungeon, madame taussauds, sea life,Shreck, paseo en el rio tamesis, proyección 4D antes de ir al London eye en días diferentes.\n\nPaseos por la ciudad.- Compras en Oxford Street, camdem town (punk – rock), Big ben, china town, bahía de westminster, Buckingham Palace, la city (corazón financiero de Londres, paseo en la estación King’s Cross (Harry potter plataforma 9 3⁄4) Barrio Soho, Abbey road, Hyde Park, Obras de teatro como el rey león o Harry potter , partidos de football o rugby, Tower Bridge.\n\nFines de semana.- Cambridge, Bath y stonehenge, Thorpe Park, Harry Potter studios entre otros.'
        }
      />
      <ArticleFooter />
    </div>
  );
};

export default Futbol;
