import React from 'react';

const ArticleFooter = () => {
  return (
    <div id="articleFooter">
      <div className="articleFooterContainer">
        <div className="separatorSmall" />
        <p>Coordinador General: Victor Mendez</p>
        <p>Representante Autorizado de Bloomsbury International</p>
      </div>
    </div>
  );
};

export default ArticleFooter;
